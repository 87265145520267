<template>
	<div class="container flex f-column f-align">
		<span class="font40 black bold">订单列表</span>
		<div style="height: 580px;padding-top: 60px;">
			<div class="flex f-align f-justifyB order-box" v-for="item in dataList" :key="item.id">
				<div class="flex f-align">
					<img :src="item.goodsUrl">
					<span class="font30 white ml20">{{item.goodName}}</span>
				</div>
				<div class="bind-btn" v-if="!item.shoeBuckleNumber" @click="$router.push('./shoesCode?id='+ item.id)">去绑定</div>
				<div class="bind-btn bind-btn1" v-else>已绑定</div>
			</div>
		</div>
		<div style="padding:0 100px">
			<van-pagination @change="changeCurrent" v-model="query.page" :items-per-page='query.limit' :total-items="total" :show-page-size="3" force-ellipses hide-on-single-page />
		</div>
		
		<van-dialog v-model="show" width="900px" title="提示" @confirm="$router.push('./quClothes')" @cancel="$router.push('/')" confirmButtonText="继续取衣" confirm-button-color="#00BF75" :showCancelButton="true" cancelButtonText="返回首页" cancelButtonColor="#F40017">
			<div class="font48 f-center">当前订单已绑定完成</div>
		</van-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				total: 0,
				dataList: [],
				query: {
					orderNumber: localStorage.getItem('orderNumber'),
					page: 1,
					limit: 4,
				},
				show: false
			};
		},
		mounted() {
			this.$emit('change', '/quClothes')
			this.getDataList();
			this.$post("/OrderDetail/list", {
				orderNumber: localStorage.getItem('orderNumber'),
				page: 1,
				limit: 999,
			}).then(res => {
				if (res.code == 0) {
					let arr = res.data.filter(item => {
						return !item.shoeBuckleNumber
					})
					if (arr.length == 0) {
						this.show = true
					}
				}
			});
		},
		methods: {
			changeCurrent(value) {
				this.getDataList()
			},
			getDataList() {
				const loading = this.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)',
					target: '.container'
				});
				this.$post("/OrderDetail/list", this.query).then(res => {
					loading.close()
					if (res.code == 0) {
						this.dataList = res.data;
						this.total = res.count
					} else {
						this.dataList = []
						this.total = 0
					}
				}).catch(() => {
					loading.close()
				})
			}
		}
	};
</script>
<style scoped lang="scss">
	.container {
		padding: 40px;
	}

	.order-box {
		width: 900px;
		height: 100px;
		background: rgba(64, 130, 253, 1);
		border-radius: 10px;
		padding: 0 60px 0 40px;
		margin-bottom: 20px;

		img {
			width: 80px;
			height: 80px;
			border-radius: 10px;
		}

		.bind-btn {
			width: 130px;
			height: 46px;
			background: rgba(255, 255, 255, 1);
			border-radius: 25px;
			font-size: 24px;
			line-height: 46px;
			color: #4082FD;
			text-align: center;
		}

		.bind-btn1 {
			background: #C1C1C1;
			color: #FFFFFF;
		}
	}

	::v-deep .van-pagination__item {
		min-width: 45px;
		height: 45px;
		border-radius: 3px;
		color: #333333;
		background-color: #D1D1D1;
		margin-left: 40px;
		font-size: 24px;
	}

	::v-deep .van-pagination__next,
	::v-deep .van-pagination__prev {
		border-radius: 0;
		background-color: #fff;
		color: #333333;
		font-size: 36px;
	}

	::v-deep .van-pagination__item--active {
		color: #fff;
		background-color: #1989fa;
	}
	
	::v-deep .van-dialog {
		border-radius: 30px;
	}
	
	::v-deep .van-dialog__header {
		font-size: 48px;
		line-height: 70px !important;
		padding-top: 48px;
		position: relative;
		padding-bottom: 0;
		color: #000000;
		font-weight: 400;
	}
	
	/* .van-dialog__header::after {
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: -4px;
		content: '';
		width: 210px;
		height: 7px;
		background-color: #FF8400;
	} */
	::v-deep .van-dialog__content {
		padding-top: 80px !important;
		padding-bottom: 110px !important;
		font-size: 45px;
		line-height: 63px;
		font-weight: 800;
		color: #000000;
	}
	
	::v-deep .van-dialog__cancel,
	::v-deep .van-dialog__confirm {
		height: 85px !important;
		font-size: 40px !important;
		border-top: 2px solid #AAAAAA;
	}
</style>
